import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

import discordImg from './img/discord.svg'
import twitterImg from './img/twitter.svg'
import logoWhite from './img/logo_white.svg'
import logo from './img/logo.svg'
import './App.css'
import Home from './pages/home/Home'

function App() {
  return (
    <div className="app">
      <Router>
        <header>
          <Link to="/">
            <img src={logoWhite} alt="MissionControl" />
          </Link>
          <p className="header__social">
            <span>Follow Us</span>{' '}
            <a href="https://discord.gg/ta6hYeEB4k" target="blank">
              <img src={discordImg} alt="Discord" />
            </a>
            <a href="https://twitter.com/mcontrol_ml" target="blank">
              <img src={twitterImg} alt="Twitter" />
            </a>
          </p>
        </header>
        <Routes>
          <Route path="/*" element={<Home />} />
          {/*<Route path="/*" element={<Page404 />} />*/}
        </Routes>

        <footer>
          <Link to="/">
            <img src={logo} alt="MissionControl" />
          </Link>
          <p>© MissionControl {new Date().getFullYear()}. All rights reserved.</p>
          <Link to="/" className='terms'>
            Terms of service
          </Link>
        </footer>
      </Router>
    </div>
  )
}

export default App
