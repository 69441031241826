import { useState, useEffect } from 'react'
import { Parallax } from 'react-scroll-parallax'
import axios from 'axios'

import './Home.css'

import circularShadow from './img/circularShadow.svg'
import planetLeft from './img/planetLeft.svg'
import planetTop from './img/planetTop.svg'
import shadowTop from './img/shadowTop.svg'
import planetRight from './img/planetRight.svg'
import planetBottom from './img/planetBottom.svg'
import rocket from './img/rocket.svg'

import coins from './img/coins.png'
import secure from './img/secure.svg'
import eco from './img/eco.svg'
import dev from './img/dev.svg'

import triangle from './img/triangle.svg'
import rightShadow from './img/rightShadow.svg'

import mail from './img/email.svg'
import discord from './img/discord.svg'

interface StakedData {
  luna?: number
  ngm?: number
  total?: number
}

function formatNumber(x: number | undefined) {
  return x !== undefined
    ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
    : 'loading...'
}

export default function Home() {
  const [staked, setStaked] = useState<StakedData>({})

  useEffect(() => {
    axios.get('https://api.mcontrol.dev/staked').then(({ data }) => {
      setStaked(data)
    })
  }, [])

  return (
    <main>
      <section className="landing safearea">
        <div className="landing__bg" style={{ width: '100vw' }}>
          <Parallax
            speed={15}
            style={{ position: 'absolute', top: 0, left: 0 }}
          >
            <img src={circularShadow} alt="circular shadow" />
          </Parallax>
          <Parallax
            speed={20}
            style={{ position: 'absolute', top: '45vh', left: 0 }}
          >
            <img src={planetLeft} alt="planet" />
          </Parallax>
          <Parallax
            speed={10}
            style={{ position: 'absolute', top: 50, left: '56vw' }}
          >
            <img src={planetTop} alt="planet" className="noMobile" />
          </Parallax>
          <Parallax
            speed={20}
            style={{ position: 'absolute', top: 150, left: '64vw' }}
          >
            <img src={shadowTop} alt="shadow" className="noMobile" />
          </Parallax>
          <Parallax
            speed={30}
            style={{ position: 'absolute', top: '40vh', right: 0 }}
          >
            <img src={planetRight} alt="planet" />
          </Parallax>
          <Parallax
            speed={10}
            style={{ position: 'absolute', top: '70vh', right: '10vw' }}
            className="planet__bottom"
          >
            <img src={planetBottom} alt="planet" />
          </Parallax>
          <img src={rocket} alt="Rocket" className="rocket" />
        </div>
        <Parallax speed={-3}>
          <div className="landing__container">
            <h1>
              We offer <span>secure</span> and <span>reliable</span> <br />{' '}
              services on the Terra blockchain
            </h1>
            <div className="landing__buttons">
              <a
                href="https://station.money/stake/terravaloper1sextn6xd46edrc02tf6d2fysmqw62qu7ng3kkm#delegate"
                target="blank"
              >
                Delegate LUNA
              </a>
            </div>
            <p className="landing__stats">
              Our validator is currently securing <br className="noMobile" />{' '}
              {formatNumber(staked.luna)} LUNA, worth <span>{formatNumber(staked.total)} $</span>
            </p>
          </div>
        </Parallax>
      </section>
      <section className="about safearea">
        <div className="about__coins">
          <img src={coins} alt="coins" />
          <h3>
            Why should you stake <br />
            with us?
          </h3>
        </div>
        <div className="about__feathures">
          <div className="feathure">
            <img src={secure} alt="Secure" />
            <div>
              <h4>Secure</h4>
              <p>
                We use the highest security standards to ensure maximum uptime,
                including a sentry architecture with nodes worldwide and 24/7
                monitoring
              </p>
            </div>
          </div>
          <div className="feathure">
            <img src={eco} alt="Eco-friendly" />
            <div>
              <h4>Eco-friendly</h4>
              <p>
                We care about the environment and we are committed to minimizing
                the carbon footprint due to the energy used by our servers
              </p>
            </div>
          </div>
          <div className="feathure">
            <img src={dev} alt="Developers" />
            <div>
              <h4>Explorer</h4>
              <p>
                We have a public block explorer for Terra, you can visit it at{' '}
                <a
                  href="https://finder.mcontrol.dev"
                  target="_blank"
                  rel="noreferrer"
                >
                  finder.mcontrol.dev
                </a>.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="stake safearea">
        <img src={triangle} alt="Triangle" className="stake__triangle" />
        <h2>
          Ready to Stake? <br />
          Earn staking rewards on Terra.
        </h2>
        <div className="stake__buttons">
          <a
            href="https://station.money/stake/terravaloper1sextn6xd46edrc02tf6d2fysmqw62qu7ng3kkm#delegate"
            target="blank"
          >
            Delegate LUNA
          </a>
        </div>
      </section>
      <section className="help safearea" style={{ position: 'relative' }}>
        <Parallax
          speed={10}
          style={{ position: 'absolute', top: 30, left: '14vw' }}
        >
          <img src={shadowTop} alt="shadow" />
        </Parallax>
        <Parallax
          speed={20}
          style={{ position: 'absolute', top: 100, right: 0 }}
        >
          <img src={rightShadow} alt="planet" />
        </Parallax>
        <h3>Need help?</h3>
        <p>Our team is at your service for any question.</p>
        <div className="support__container">
          <div className="support">
            <p>Send us an email</p>
            <div className="support__line"></div>
            <a
              href="mailto:stake@mcontrol.dev"
              target="blank"
              className="support__email"
            >
              <img src={mail} alt="email" />
              stake@mcontrol.dev
            </a>
          </div>
          <div className="support">
            <p>Join Our Discord Server</p>
            <div className="support__line"></div>
            <a
              href="https://discord.gg/ta6hYeEB4k"
              target="blank"
              className="support__discord"
            >
              <img src={discord} alt="Discord" />
            </a>
          </div>
        </div>
      </section>
    </main>
  )
}
